<template>
    <div class="saveKitNameSet">
        <div class="main-flex">
            <edit-btns class="btn-box" 
                :bnts="['goBack','recalculate','output']"
                @recalculateClick="heSuan()"
                @outputClick="exportExcel()"
                @goBackClick="$router.back()"/>
        </div>
        <div class="content-box">
            <div class="flex-box">
                <div class="left-table">
                    <div class="title">厨师列表</div>
                    <div class="table-box" ref="kitNameTable">
                        <el-table class="el-table--scrollable-y" style="width: 100%;height:100%"  v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}"
                            :data="pageKitTableData"
                            :row-class-name="({row})=>row.User_ID==currentInfo?.User_ID?'current-row':''"
                            @row-click="currentChange"
                            >
                            <el-table-column label="序号" width="50" type="index" :index="(index)=>index+(pageIndex-1)*pagesize+1" />
                            <el-table-column prop="User_Code" label="编码" min-width="90" align="left"></el-table-column>
                            <el-table-column prop="User_Name" label="姓名" min-width="90" align="left"></el-table-column>
                            <el-table-column label="操作" width="80">
                                <template #default="scope">
                                    <i class="iconfont icon-a-beizhu2" @click="edit(scope.row)" ></i>
                                    <i class="iconfont icon-shanchu" @click="delData(scope.row,scope.$index)"></i>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="bottom-search-box">
                        <div class="add-bnt" @click="add()"><i class="iconfont icon-jia"></i>添加厨师</div>
                        <div class="search-box" style="flex: 1;">
                            <key-board-input class="search"
                                type="text" v-model="kitSearchText" placeholder="按名称检索"
                                isBoard isKeyEnterClose isBlurClose
                                :keyOptions="{isKeyDownEnter:true}"
                            ></key-board-input>
                        </div>
                        <div class="page-box-bottom">
                            <page-turning class="last-page" :pageCount="kitPageCount" v-model="pageIndex" type="up"><i class=" iconfont icon-fangxiangquan-left"></i></page-turning>
                            <span class="page-number">{{pageIndex}}/{{kitPageCount}}页</span>
                            <page-turning class="next-page" :pageCount="kitPageCount" v-model="pageIndex"><i class=" iconfont icon-fangxiangquan-right"></i></page-turning>
                        </div>
                    </div>
                </div>
                <div class="right-table">
                    <div class="btn-box">
                        <div>
                             操作厨师:
                             <span v-if="currentInfo">{{currentInfo.User_Name}}</span>
                             <span class="red" v-else>请点击左边列表选择厨师</span>
                        </div>
                        <div class="right-bnt">
                            <div class="bnt del-bnt" @click="delKitFood()"><i class="iconfont icon-shanchu"></i>批量删除</div>
                        </div>
                    </div>
                    <div class="table-box" ref="kitFoodsTable">
                        <el-table class="el-table--scrollable-y" ref="kitFoods" style="width: 100%;height:100%"  
                            highlight-current-row
                            v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}"
                            :data="pageKitNameFoods"
                            @selection-change="(val)=>{multipleFoodsTable=val}"
                        >
                            <el-table-column label="序号" width="50" type="index" :index="(index)=>index+(pageIndex2-1)*pagesize2+1" />
                            <el-table-column type="selection" :selectable="(row)=>row.User_ID" width="40" />
                            <el-table-column prop="Food_Code" label="菜品编码" min-width="80" ></el-table-column>
                            <el-table-column prop="Food_Name" label="菜品名称" min-width="100" ></el-table-column>
                            <el-table-column prop="SubCategory_Name" label="小类名称" min-width="80" ></el-table-column>
                            <el-table-column prop="USER_NAME" label="厨师名称" min-width="100" ></el-table-column>
                            <el-table-column label="操作" width="60" type="edit">
                                <template #default="scope">
                                    <i v-if="scope.row.User_ID" class="iconfont icon-shanchu" @click="delKitFood(scope.row,scope.$index)"></i>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="bottom-search-box">
                        <div class="add-bnt" @click="addFood()"><i class="iconfont icon-jia"></i>添加菜品</div>
                        <div class="fill-box"></div>
                        <el-select class="selectType" v-model="showType"  placeholder="请选择">
                            <el-option :value="1" label="全部菜品"></el-option>
                            <el-option :value="2" label="未绑定的菜品"></el-option>
                        </el-select>
                        <div class="search-box">
                            <key-board-input class="search"
                                type="text" v-model="foodText" placeholder="菜品模糊检索"
                                isBoard isKeyEnterClose 
                                :keyOptions="{isKeyDownEnter:true}"
                            ></key-board-input>
                        </div>
                        <div class="page-box-bottom">
                            <page-turning class="last-page" :pageCount="kitFoodsPageCount" v-model="pageIndex2" type="up"><i class=" iconfont icon-fangxiangquan-left"></i></page-turning>
                            <span class="page-number">{{pageIndex2}}/{{kitFoodsPageCount}}页</span>
                            <page-turning class="next-page" :pageCount="kitFoodsPageCount" v-model="pageIndex2"><i class=" iconfont icon-fangxiangquan-right"></i></page-turning>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 厨师设置 -->
        <modal-load :isShow="chefSettingsIshow" >
            <chef-settings-model :isShow="chefSettingsIshow" :data="kitNameInfo" @closeModel="chefSettingsIshow=false" @confirm="EditKitName"></chef-settings-model>
        </modal-load>
        <!-- 厨师业绩重新核算 -->
        <modal-load :isShow="calculationIshow" >
            <calculation-model :isShow="calculationIshow" v-on:closeModel="calculationIshow=false"></calculation-model>
        </modal-load>
        <!-- 选择菜品绑定厨师 -->
        <modal-load :isShow="foodIshow" >
            <food-model :isShow="foodIshow" :foods="notFoodList" :name="currentInfo?.User_Name" @closeModel="foodIshow=false" @confirm="KitNameBindFood"></food-model>
        </modal-load>
    </div>
</template>

<script>
import chefSettingsModel from './chefSettingsModel/chefSettingsModel.vue'
import calculationModel from './calculationModel/calculationModel.vue'
import foodModel from './foodModel/foodModel.vue'
export default {
    name:"saveKitNameSet",
    components:{
        chefSettingsModel,
        calculationModel,
        foodModel
    },
    data(){
        return {
            //菜品筛选类型 
            showType:null,
            pageIndex:1,
            pagesize:5,
            //厨师检索条件
            kitSearchText:"",
            //厨师数据
            posCookers:[],
            //选中的厨师
            currentInfo:null,

            pageIndex2:1,
            pagesize2:5,
            //厨师检索条件
            foodText:"",
            //菜品数据
            kitNameFoods:[],
            //多选菜品
            multipleFoodsTable:[],
            //厨师设置 弹层
            chefSettingsIshow:false,
            //厨师业绩重新核算 弹层
            calculationIshow:false,
            //选择菜品绑定厨师 弹层
            foodIshow:false,
            //要修改的厨师数据
            kitNameInfo:null
        }
    },
    computed:{
        kitTableOrder(){
            let data=(this.posCookers||[]);
            return data.order("User_Code","ASC")
        },
        //厨师筛选数据
        kitTableFilter(){
            let text=(this.kitSearchText||"").trim().toUpperCase();
            if(text){
                return this.kitTableOrder.filter(it=>(it.User_Name||"").toUpperCase().indexOf(text)>=0);
            }
            return this.kitTableOrder;
        },
        //厨师分页数据
        pageKitTableData(){
            return this.kitTableFilter.slice((this.pageIndex-1)*this.pagesize,this.pageIndex*this.pagesize);
        },
        //厨师总页数
        kitPageCount(){
            if(this.kitTableFilter?.length>0){
                return Math.ceil(this.kitTableFilter.length/this.pagesize);
            }
            return 1;
        },
        //厨师绑定的菜品 筛选数据
        kitNameFoodsFiler(){
            if(!this.currentInfo && !this.showType){
                return [];
            }
            let data=this.kitNameFoods||[];
            if(this.showType==2){
                data=data.filter(it=>!it.User_ID);//查看未绑定菜品
            }
            if(this.currentInfo?.User_ID){
                data=data.filter(it=>it.User_ID==this.currentInfo.User_ID);
            }
            let text=(this.foodText||"").trim().toUpperCase();
            if(text){
                data= data.filter(it=> (it.Food_Name||"").toLowerCase().indexOf(text)>=0 
                || (it.Food_PinYin||"").toLowerCase().indexOf(text)>=0 
                || (it.Food_Code||"").toLowerCase().indexOf(text)>=0);
            }
            return data;
        },
        /**分页数据 */
        pageKitNameFoods(){
            return this.kitNameFoodsFiler.slice((this.pageIndex2-1)*this.pagesize2,this.pageIndex2*this.pagesize2);
        },
        ///厨师绑定的菜品总页数
        kitFoodsPageCount(){
            if(this.kitNameFoodsFiler?.length>0){
                return Math.ceil(this.kitNameFoodsFiler.length/this.pagesize2);
            }
            return 1;
        },
        //未绑定厨师的菜品
        notFoodList(){
            return (this.kitNameFoods||[]).filter(it=>!it.User_ID);
        }
    },
    mounted(){
        //厨师
        console.log("厨师")
        this.$cacheData.PosCookers().then((d)=>{
            this.posCookers=d;
        }).catch((e)=>{
            this.$message.error('加载厨师数据失败：'+e,);
            console.log('加载厨师数据失败：'+e);
        })
        this.loadData();
        this.$nextTick(()=>{
            this.setKitPageSize();
            this.setKitFoodsPageSize();
        });
        
    },
    watch:{
        showType(newVal){
            if(newVal==1 || newVal==2){
                this.currentInfo=null;
            }
            this.pageIndex2=1;
        },
        kitSearchText(newVal){
            if(newVal){
                this.pageIndex=1;
            }
        },
        currentInfo(newVal){
            if(newVal){
                this.showType='';
            }
            this.pageIndex2=1;
        },
        kitFoodsPageCount(newVal){
            if(newVal<this.pageIndex2){
                this.pageIndex2=1;
            }
        },
        foodText(newVal){
            if(newVal){
                this.pageIndex2=1;
            }
        },
    },
    methods:{
        /**厨师列表 计算 一页显示多少条数据 */
        setKitPageSize(){
            if(this.$refs.kitNameTable){
                this.$nextTick(()=>{
                    let height=this.$refs.kitNameTable.clientHeight - 30;
                    this.pagesize=parseInt(height/40);
                })
            }
        },
        /**厨师列表 计算 一页显示多少条数据 */
        setKitFoodsPageSize(){
            if(this.$refs.kitNameTable){
                this.$nextTick(()=>{
                    let height=this.$refs.kitFoodsTable.clientHeight - 30;
                    this.pagesize2=parseInt(height/40);
                })
            }
        },
        //加载数据
        loadData(){
            const loading = this.$loading({
                text: "获取厨师菜品数据中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetKitNameFoodMain", {}).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error(d.ResponseHeader.ResultDesc)
                    return;
                }else{
                    this.kitNameFoods=d.ResponseBody;
                }
            }).catch((e)=>{
                loading.close();
                this.$alert("厨师菜品数据加载失败"+e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        add(){
            this.chefSettingsIshow=true;
            this.kitNameInfo=null;
        },
        edit(row){
            this.chefSettingsIshow=true;
            this.kitNameInfo={
                User_ID:row.User_ID,
                USER_CODE:row.User_Code,
                USER_NAME:row.User_Name,
                USERGROUPID:row.USERGROUPID,
                ACCESS_CARDNUMBER:row.ACCESS_CARDNUMBER
            };
        },
        delData(row){
            this.$confirm("您确认删除厨师吗？","提示", {
                confirmButtonText: "继续",
                cancelButtonText:"取消",
                callback: (name) => {
                    if(name=='confirm'){
                        this.DelKitName(row);
                    }
                },
            })
        },
        //删除厨师
        DelKitName(row){
            const loading = this.$loading({
                text: "数据删除中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.DelKitName",{
                User_ID:userInfo.User_ID,
                accountID:row.User_ID,
                Operator_Name:userInfo?.Login_Name,
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("删除成功");
                    let index= this.posCookers.findIndex(it=>it.User_ID==row.User_ID);
                    if(index>=0){//删除
                        this.posCookers.splice(index,1);
                    }
                    this.kitNameFoods?.forEach(it=>{
                        if(it.User_ID==row.User_ID){//清除绑定的菜品
                            it.User_ID=undefined;
                            it.USER_NAME=undefined;
                            it.USER_CODE=undefined;
                        }
                    })
                    if(this.currentInfo?.User_ID==row.User_ID){
                        this.currentInfo=null;
                    }
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('厨师删除失败：'+e.message);
                console.log('厨师删除失败：',e);
            })
        },
        heSuan(){
            this.calculationIshow=true;
        },
        //选中厨师
        currentChange(currentRow){
            this.currentInfo=currentRow;
        },
        //添加菜品
        addFood(){
            if(!this.currentInfo){
                this.$message.warning("请先选择厨师后再添加菜品!");
                return;
            }
            this.foodIshow=true;
        },
        //新增或修改厨师
        EditKitName(info){
            if(!info){return;}
            if(!info.USER_CODE){
                this.$message.warning("编号不能为空!");
                return;
            }
            if(!info.USER_NAME){
                this.$message.warning("姓名不能为空!");
                return;
            }
            
            if(!info.USERGROUPID){
                this.$message.warning("分组不能为空!");
                return;
            }
            
            const loading = this.$loading({
                text: "数据保存中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.EditKitName",{
                User_ID:userInfo.User_ID,
                Operator_Name:userInfo?.Login_Name,
                accountID:info.User_ID,
                USER_CODE:info.USER_CODE,
                USER_NAME:info.USER_NAME,
                USERGROUPID:info.USERGROUPID,
                ACCESS_CARDNUMBER:info.ACCESS_CARDNUMBER
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("保存成功");
                    console.log("保存成功",d.ResponseBody)
                    if(d.ResponseBody){
                        if(info.User_ID){//修改
                             this.posCookers.find(it=>{
                                if(it.User_ID==info.User_ID){
                                    it.User_Code=d.ResponseBody.User_Code;
                                    it.User_Name=d.ResponseBody.User_Name;
                                    it.USERGROUPID=d.ResponseBody.USERGROUPID;
                                    it.ACCESS_CARDNUMBER=d.ResponseBody.ACCESS_CARDNUMBER;
                                    return true;
                                }
                                return false;
                             })
                        }else{//新增
                            if((this.posCookers||[]).length==0){
                                this.posCookers=[];
                            }
                            this.posCookers.push(d.ResponseBody);
                        }
                        
                        this.chefSettingsIshow=false;
                    }
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('厨师保存失败：'+e.message);
                console.log('厨师保存失败：',e);
            })
        },
        //厨师绑定菜品
        KitNameBindFood(AutoIDs){
            if((AutoIDs||[]).length==0){
                 this.$message.warning("请先勾选要绑定的菜品!");
                return;
            }
            const loading = this.$loading({
                text: "数据保存中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.KitNameBindFood",{
                User_ID:userInfo.User_ID,
                Operator_Name:userInfo?.Login_Name,
                accountID:this.currentInfo.User_ID,
                AutoIDs:AutoIDs,
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("保存成功");
                    let list=d.ResponseBody;
                    this.foodIshow=false;
                    this.kitNameFoods?.forEach((it)=>{
                        if(list?.some(p=>p.Eat_XFBMID==it.Food_ID)){
                            it.User_ID=this.currentInfo.User_ID;
                            it.USER_NAME=this.currentInfo.User_Name;
                            it.USER_CODE=this.currentInfo.User_Code;
                        }
                    })
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('厨师保存失败：'+e.message);
                console.log('厨师保存失败：',e);
            })
        },
        //解除厨师绑定菜品
        DelKitNameBindFood(AutoIDs){
            const loading = this.$loading({
                text: "数据删除中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.DelKitNameBindFood",{
                User_ID:userInfo.User_ID,
                Operator_Name:userInfo?.Login_Name,
                accountID:this.currentInfo.User_ID,
                AutoIDs:AutoIDs,
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("删除成功");
                    this.foodIshow=false;
                    this.kitNameFoods?.forEach((it)=>{
                        if(AutoIDs.indexOf(it.Food_ID)>=0){
                            it.User_ID=undefined;
                            it.USER_NAME=undefined;
                            it.USER_CODE=undefined;
                        }
                    })
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('厨师绑定的菜品删除失败：'+e.message);
                console.log('厨师绑定的菜品删除失败：',e);
            })
        },
        //删除厨师菜品
        delKitFood(row){
            if(!this.currentInfo){
                this.$message.warning("请先选择厨师!");
                return;
            }
            let AutoIDs=[];
            if(row){
                AutoIDs=[row.Food_ID];
            }else if(this.multipleFoodsTable?.length>0){
                this.multipleFoodsTable.forEach(it=>{
                    if(it.User_ID){
                        AutoIDs.push(it.Food_ID);
                    }
                })
            }
            if(AutoIDs.length==0){
                this.$message.warning("请勾选要删除的菜品!");
                return;
            }
            this.$confirm(row?("您确认删除【"+row.USER_NAME+"】绑定的菜品【"+row.Food_Name+"】吗？"):"您确认删除【"+this.currentInfo.User_Name+"】绑定的菜品吗？","提示", {
                confirmButtonText: "继续",
                cancelButtonText:"取消",
                callback: (name) => {
                    if(name=='confirm'){
                        this.DelKitNameBindFood(AutoIDs);
                    }
                },
            })
        },
        /**Excel导出 */
        exportExcel(){
            if(!this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserPrintReports){
                this.$message.warning('您没有导出报表权限，请先分配权限！');
                return;
            }
            this.$excelCommon.elTableToExcel({
                elTable:this.$refs.kitFoods,
                titleName:"厨师菜品信息",
                list:this.kitNameFoodsFiler
            })
        },
    }
}
</script>

<style lang="scss">
    @import './saveKitNameSet.scss'
</style>